<template>
  <!--
    选择员工：支持单选、多选
    监听 change 返回：
    {
      visible: Boolean, // 弹窗显隐
      list: Array / null // 选择的员工，点击确定返回数组，点击取消返回null
    }
  -->
  <el-dialog
    :title="title"
    :visible="visible"
    width="800px"
    top="50px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :show-close="false"
    :destroy-on-close="false"
  >
    <el-form
      ref="listSearchForm"
      :model="searchForm"
      label-width="90px"
      size="mini"
    >
      <el-row>
        <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="searchForm.name" placeholder="姓名" />
          </el-form-item>
        </el-col>
        <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}">
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="searchForm.phone" placeholder="手机号" />
          </el-form-item>
        </el-col>
        <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}">
          <el-form-item label-width="20px">
            <el-button type="primary" @click="searchFormSubmit('listSearchForm')">查 询</el-button>
            <el-button type="primary" plain @click="searchFormReset('listSearchForm')">重 置</el-button>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="已选择人员">
            <el-tag v-for="(emp, index) of chooseUsers" :key="'emp-' + index" class="empTag" size="small" closable @close="depEmp(index)">{{ emp.name }}</el-tag>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <!-- table表格 -->
    <el-table
      ref="empTable"
      v-loading="tableLoading"
      :data="tableList"
      row-key="id"
      element-loading-text="加载中..."
      fit
      size="mini"
      @select="selectionChange"
      @select-all="selectionChange"
    >
      <!-- @selection-change="selectionChange" -->
      <el-table-column
        :reserve-selection="true"
        type="selection"
        width="60"
        align="center"
      />
      <el-table-column
        type="index"
        label="序号"
        :index="tableIndex"
        align="center"
        width="60"
      />
      <el-table-column label="姓名" prop="name" width="100" align="center"/>
<!--      <el-table-column label="工号" prop="jobNumber" width="100" />-->
      <el-table-column label="部门" prop="deptName" align="center"/>
      <el-table-column label="手机号" prop="phone" align="center"/>
      <el-table-column label="邮箱" prop="email" align="center"/>
    </el-table>

    <!-- 分页 -->
    <pagination
      :page.sync="tablePage.currentPage"
      :total="tablePage.total"
      :limit.sync="tablePage.pageSize"
      style="padding: 10px 0"
      @pagination="paginationChange"
    />

    <!-- 页脚 -->
    <div slot="footer" class="dialog-footer">
      <el-button  @click="close" size="mini">取 消</el-button>
      <el-button  type="primary" @click="sureClick" size="mini">确 认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import tables from '@/mixins/tables'
import Pagination from '@/components/Pagination/index' // 分页
import service from '@/api/department'

export default {
  name: 'EmployeeSelect',
  components: { Pagination },
  mixins: [tables],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '选择员工'
    },
    initList: {
      type: Array,
      default: () => ([])
    },
    // 最大员工数，默认: 0，不限制
    max: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      /**
       * 存在搜索条件，字段必须在 searchForm 里！！！
       * 没有搜素条件，建议删除 searchForm
       */
      searchForm: {
        name: '',
        phone: ''
      },
      // 分页字段
      tablePage: {
        currentPage: 1,
        pageSize: 5,
        total: 0
      },

      // 已选择人员
      chooseUsers: [],
      row: {},
    }
  },
  watch: {
    // 初始化数据
    initList(newVal, oldVal) {
      this.chooseUsers = newVal
      if (newVal.length && this.tableList.length) {
        newVal.forEach(item => {
          let hasIndex = this.tableList.findIndex(chooseUser => chooseUser.id === item.id)
          if (hasIndex !== -1) {
            // this.row = this.tableList[hasIndex];
            this.tableList[0].createDate = 1
          }
        })
      }
    },
    // 表格数据
    tableList(newVal, oldVal) {
      this.$nextTick(() => {
        if (this.chooseUsers.length && newVal.length) {
          this.chooseUsers.forEach(item => {
            let hasIndex = newVal.findIndex(chooseUser => chooseUser.id === item.id)
            if (hasIndex !== -1) {
              this.$refs.empTable.toggleRowSelection(newVal[hasIndex])
            }
          })
        }
      })
    }
  },
  methods: {
    /**
     * ！！！必须方法，获取 list 接口
     *
     * @params {Object} data 请求数据：包含 searchForm 和 tablePage 属性
     * @return {Promise} Promise 获取 list 接口的异步方法
     */
    async getListApi(data) {
      return new Promise((resolve, reject) => {
        service.userPageList(data).then(data => {
          resolve(data.data)
        }).catch(e => {
          reject(e)
        })
      })
    },



    // 选择
    selectionChange(users, user) {
      let chooseUsers = [...this.chooseUsers]
      // this.tableList.forEach((item, index) => {
      let isMsg = false
      for (let index = 0; index < this.tableList.length; index++) {
        let item = this.tableList[index]
        let cIndex = this.chooseUsers.findIndex(cItem => cItem.id === item.id)
        let uIndex = users.findIndex(uItem => uItem.id === item.id)
        if (cIndex === -1 && uIndex !== -1) {
          if (this.max > 0 && chooseUsers.length >= this.max) {
            !isMsg && this.$message({
              message: `最多选择${this.max}个人员！`,
              type: 'warning',
              showClose: true
            })
            isMsg = true
            this.$refs.empTable.toggleRowSelection(this.tableList[index], false)
          } else {
            chooseUsers.push(this.tableList[index])
          }
        } else if (cIndex !== -1 && uIndex === -1) {
          chooseUsers = chooseUsers.filter(cfItem => cfItem.id !== item.id)
        }
      }
      // })

      this.chooseUsers = chooseUsers
    },

    // 删除
    depEmp(index) {
      let emp = Object.assign({}, this.chooseUsers[index])
      let listIndex = this.tableList.findIndex(item => item.id === emp.id)
      this.chooseUsers.splice(index, 1)
      if (listIndex !== -1) {
        this.$refs.empTable.toggleRowSelection(this.tableList[listIndex], false)
      }
    },

    sureClick() {
      this.emitChange(false, this.chooseUsers)
    },
    close() {
      this.emitChange()
    },
    emitChange(visible = false, list = null) {
      this.$emit('change', {
        visible,
        list
      });
      this.chooseUsers=[];
      this.$refs.empTable.clearSelection();
    }
  }
}
</script>

<style scoped>
.empTag {
  margin-right: 5px;
}
>>>.el-dialog__body{
  padding: 10px 10px;
}
</style>
