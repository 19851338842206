<template>
  <el-dialog
      :title="'变更公司'"
      :visible.sync="dialogVisible"
      width="900px"
      :append-to-body="true"
      destroy-on-close
      @closed="$emit('update:activated', false)">
    <div style="width: 95%; margin: auto;margin-top: 15px">
      <el-form :model="searchForm" :inline="true" size="mini">
        <el-form-item label="公司:">
          <el-input v-model="searchForm.name" clearable placeholder="请输入公司"></el-input>
        </el-form-item>
        <el-form-item label="编码:">
          <el-input v-model="searchForm.code" clearable placeholder="请输入编码"></el-input>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button type="primary" size="mini" @click="getList">查询</el-button>
        </el-form-item>
      </el-form>
      <vxe-table
          ref="xTable"
          row-id="orgId"
          size="mini"
          resizable
          :sort-config="{showIcon: false}"
          show-overflow
          show-header-overflow
          highlight-hover-row
          highlight-current-row
          height="280"
          stripe
          :data="tableList"
          @checkbox-change="selectChange">
        <vxe-table-column type="checkbox" width="60" fixed="left"></vxe-table-column>
        <vxe-table-column field="name" align="center" title="公司" sortable></vxe-table-column>
        <vxe-table-column field="code" align="center" title="公司编码" sortable></vxe-table-column>
      </vxe-table>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
      <el-button type="primary" :loading="saveLoading" size="mini" @click="saveChange">确定</el-button>
    </div>

  </el-dialog>
</template>

<script>
import service from '@/api/department'

export default {
  name: "department-dialog",
  props: {
    row: {}
  },
  data() {
    return {
      searchForm: {
        name: '',
        code: '',
      },
      dialogVisible: true,
      tableLoading: false,
      selectRows: [],
      tableList: [],
      saveLoading: false,
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.tableLoading=true;
      service.getOrgByHeadCode({typeCode: 3, headCode: this.row && this.row.headCode, ...this.searchForm}).then(resp => {
        if(resp.success){
          this.tableList = resp.data;
        }else {
          this.$message.error(resp.message)
        }
      }).finally(() => {
        this.tableLoading=false;
      })
    },
    //选中行
    selectChange(selection) {
      this.selectRows = selection.records;
    },
    //保存
    saveChange(){
      if(this.selectRows.length >1){
        this.$message.warning('只能选择一条');
        return;
      }
      this.saveLoading = true;
      let params = {
        id : this.row.id,
        name : this.row.name,
        managerId : this.row.charger,
        typeCode : this.row.typeCode,
        code : this.row.code,
        parentId : this.selectRows[0].id,
      }
      service.saveDepartment(params).then(data => {
        if(data.success){
          this.dialogVisible = false;
          this.$message.success('变更成功')
          this.$emit('close')
        }else{
          this.$message.error(data.message);
        }
      }).finally(()=> {
        this.saveLoading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
