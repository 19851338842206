import {getRequest,postRequest,putRequest,deleteRequest} from "@/utils/request_utils"

export default {
  getDepartmentList:(params) =>{
    return getRequest(`/api-base/dept/getList`,{params});
  },
  removeDepartment:(data) => {
    return postRequest(`/api-base/dept/delete`,{data});
  },
  saveDepartment:(data) => {
    return postRequest(`/api-base/dept/save`,{data});
  },
  userPageList:(params) => {
    // return getRequest(`/api-base/loginUser/pageList`, {params});
    return getRequest(`/api-base/loginUser/pageListOnEncryption`, {params});
  },
  saveUser:(data) => {
    return postRequest(`/api-base/loginUser/save`,{data});
  },
  //权限控制到部门
  getUserByDept(params) {
    return getRequest(`/api-base/loginUser/getUserVoByCondition`, {params});
  },
  removeUser:(data)=> {
    return postRequest(`/api-base/loginUser/delete`,{data})
  },
  //启用禁用
  stopAndUse: (data)=> {
    return postRequest(`/api-base/loginUser/disableAndEnable`, {data})
  },
  listNoPage: (params)=> {
    return getRequest(`/api-base/loginUser/list`,{params});
  },
  userInfo: (params)=> {
    return getRequest(`/api-base/loginUser/info`,{params});
  },
  //下载人员导入模板
  downModule: (params) => {
    return getRequest(`/api-base/import/download/costConfigTemplate`, {params, responseType: 'blob'})
  },
  //变更列表
  getOrgByHeadCode: (data) => {
    return postRequest(`/api-base/dept/getOrgByHeadCode`, {data})
  },

  getTree(data){
    data.forEach(item => {
      item.children = item.deptVos && item.deptVos.length > 0 ? item.deptVos : undefined;
      item.label = item.name;
      if(item.deptVos){
        this.getTree(item.deptVos)
      }
    })
    return data;
  }


}
