<template>
  <div class="app-container">
    <div class="bg-white main-content-wrapper flex flex-v" style="height: 100%">
      <div style="text-align: right;margin-bottom: 15px">
        <el-button  size="mini" type="primary" icon="el-icon-plus" @click="depEdit('add')"> 创建部门 </el-button>
      </div>
      <el-table
          class="flex-1 "
          header-cell-class-name="avue-crud"
          size="mini"
          border
          height="666px"
          ref="departmentTable"
          :data="depData"
          v-loading="tableLoading"
          row-key="id"
          :tree-props="{children: 'children', hasChildren: 'hasChild'}"
          :expand-row-keys="['0']"
          lazy
      >
        <el-table-column
            prop="name"
            label="部门名称"
            width="200"
            align="center"
            show-overflow-tooltip
        />
        <el-table-column
            prop="code"
            label="部门编码"
            align="center"
            show-overflow-tooltip
        />
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button v-if="!scope.row.root" type="primary"  size="mini" @click="depEdit('edit', scope.row)">编辑</el-button>
            <el-button v-if="scope.row.typeCode != 4"  type="success" plain size="mini" @click="depEdit('addChild', scope.row)">添加子部门</el-button>
            <el-button v-if="scope.row.typeCode === 4" type="primary" plain size="mini" @click="changeDept(scope.row)">变更</el-button>
            <el-button v-if="!scope.row.root" type="danger" plain size="mini" @click="depDel(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

   <!-- 变更部门 -->
    <department-dialog v-bind.sync="dialogParams" v-if="dialogParams.activated" @close="init"></department-dialog>

    <!-- 弹框 -->
    <el-dialog
      :title="depDialogTitle"
      :visible.sync="depVisible"
      width="500px"
      destroy-on-close
    >
      <el-form
        style="width: 90%; margin: auto"
        size="mini"
        ref="depEditForm"
        :model="depEditForm"
        :rules="depEditFormRules"
        label-width="110px"
      >
        <el-form-item label="部门名称" prop="name">
          <el-input v-model="depEditForm.name" placeholder="部门名称" />
        </el-form-item>
        <el-form-item label="部门编码" prop="code">
          <el-input v-model="depEditForm.code" placeholder="部门编码" />
        </el-form-item>
<!--        <el-form-item label="负责人" prop="charger" v-if="depEditForm.typeCode == 4">-->
<!--          <div class="el-input el-input&#45;&#45;mini el-input-group el-input-group&#45;&#45;append">-->
<!--            <div class="el-input__inner">-->
<!--              <template v-if="getChargerList.length">-->
<!--                <el-tag-->
<!--                    v-for="(emp, index) of getChargerList"-->
<!--                    :key="'emp-' + index"-->
<!--                    size="small"-->
<!--                    closable-->
<!--                >{{ emp.name }}</el-tag>-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                <span class="input-placeholder">请选择负责人</span>-->
<!--              </template>-->
<!--            </div>-->
<!--            <div class="el-input-group__append">-->
<!--              <el-button icon="el-icon-search" @click="chargerToggle(true)">选择</el-button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="depDialogVisible(false)" size="mini">取 消</el-button>
        <el-button type="primary" @click="depSubmit" size="mini" :loading="submitLoading">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 选择管理员 -->
    <select-employee
        :title="'选择负责人'"
        :visible="chargerVisible"
        :max="1"
        :init-list="getChargerList"
        @change="chargerChange"
    />
  </div>
</template>

<script>
  import BlockTitle from '@/components/BlockTitle' // 标题栏
  import service from '@/api/department'
  import SelectEmployee from '@/components/Select/Employee'

  import DepartmentDialog from './department-dialog'
  //
  // import { getList, save, del } from '@/api/department'

  export default {
    components: { BlockTitle, SelectEmployee, DepartmentDialog },
    data() {
      return {
        dialogParams: {activated: false},
        depData: [],
        tableLoading: false,
        depVisible: false, // 部门弹窗
        submitLoading: false,
        depEditForm: {
          typeCode: '',//级别
          type: '', // 操作类型 edit | add
          // 父级部门
          parentId: '',
          parentName: '',
          // 本部门
          id: '',
          name: '',
          code: '',
          // 负责人
          charger: '',
          chargerName: ''
        },

        // 负责人
        chargerVisible: false
      }
    },
    computed: {
      depDialogTitle() {
        let txt = '编辑部门'
        if (this.depEditForm.type === 'add') {
          txt = '新增部门'
        }
        return txt
      },
      depEditFormRules() {
        return {
          name: [
            { required: true, message: '请输入部门名称', trigger: 'blur' },
            { min: 2, max: 20, message: '字符长度在2 - 20之间', trigger: 'blur' }
          ],
          code: [
            { required: true, message: '请输入部门编码', trigger: 'blur' },
          ],
          charger: [
            { required: true, message: '请选择负责人', trigger: 'change' },
          ]
        }
      },
      getChargerList() {
        let charger = []
        if (this.depEditForm.charger && this.depEditForm.chargerName) {
          charger.push({
            id: this.depEditForm.charger,
            name: this.depEditForm.chargerName
          })
        }
        return charger
      }
    },
    created() {
      this.init()
    },
    methods: {
      changeDept(row){
        this.dialogParams = {activated: true, row: row};
      },
      init() {
        this.tableLoading = true;
        service.getDepartmentList({currentPage: 1, pageSize: 10}).then(resp=> {
          if(resp.data){
            let list = service.getTree(resp.data)
            this.depData = list;
          }
        }).finally(()=> {
          this.tableLoading = false;
        })

      },

      // 编辑 / 添加 部门
      depEdit(type, depData) {
        this.depEditForm.type = type

        if (type === 'edit') {
          this.depEditForm.parentId = ''
          this.depEditForm.parentName = ''
          this.depEditForm.id = depData.id
          this.depEditForm.name = depData.name
          this.depEditForm.charger = depData.managerId
          this.depEditForm.chargerName = depData.managerName
          this.depEditForm.typeCode = depData.typeCode
          this.depEditForm.code = depData.code
        } else if (type === 'addChild') {
          if(depData.typeCode === 4){
           return this.$message.error('已到最末级节点,不能继续添加!')
          }
          this.depEditForm.parentId = depData.id
          this.depEditForm.parentName = depData.name

          this.depEditForm.id = ''
          this.depEditForm.name = ''
          this.depEditForm.charger = ''
          this.depEditForm.chargerName = ''
          this.depEditForm.typeCode = depData.typeCode+1;
          this.depEditForm.code = ''
        }else if (type==='add'){
          this.depEditForm.parentId = ''
          this.depEditForm.parentName = ''
          this.depEditForm.id = ''
          this.depEditForm.name = ''
          this.depEditForm.charger = ''
          this.depEditForm.chargerName = ''
          this.depEditForm.typeCode = 0;
          this.depEditForm.code = '';
        }

        this.depDialogVisible(true)
      },
      // 删除
      depDel(id) {
        this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          service.removeDepartment({id:id}).then(res => {
            this.init()
            this.depDialogVisible(false)
            // window.location.reload()
          })
        }).catch(() => { })
      },

      // 部门
      depDialogVisible(visible) {
        this.depVisible = visible
        !visible && this.$refs.depEditForm.clearValidate()
      },
      depSubmit() {
        this.$refs.depEditForm.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            let data = {}
            if (this.depEditForm.type === 'edit') {
              data.id = this.depEditForm.id
              data.name = this.depEditForm.name
              data.managerId = this.depEditForm.charger
              data.typeCode = this.depEditForm.typeCode;
              data.code = this.depEditForm.code;
            } else if (this.depEditForm.type === 'addChild') {
              data.parentId = this.depEditForm.parentId
              data.name = this.depEditForm.name
              data.managerId = this.depEditForm.charger
              data.typeCode = this.depEditForm.typeCode;
              data.code = this.depEditForm.code;
            }else if(this.depEditForm.type === 'add'){
              data.typeCode = this.depEditForm.typeCode;
              data.name = this.depEditForm.name;
              data.code = this.depEditForm.code;
            }

            service.saveDepartment(data).then(data => {
              if(data.success){
                this.init()
                this.depDialogVisible(false)
              }else{
                this.$message.error(data.message);
              }
            }).finally(()=> {
              this.submitLoading = false;
            })
          }
        })
      },
      chargerChange(data) {
        if (data.list && data.list.length > 0) {
          this.depEditForm.chargerName = data.list[0].name
          this.depEditForm.charger = data.list[0].id

        }
        this.chargerToggle(false)
      },
      delCharger() {
        this.depEditForm.charger = ''
        this.depEditForm.chargerName = ''
      },
      // 负责人
      chargerToggle(visible) {
        this.chargerVisible = visible
      },
    }
  }
</script>

<style lang="scss" scoped>
  // 选择人员css
  .el-input__inner {
    padding: 0 5px;
    > .el-tag {
      margin-left: 5px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .input-placeholder {
    padding-left: 10px;
    padding-right: 10px;
    color: #C0C4CC;
  }
  .app-container {
    height: 100%;
    background-color: #f6f6f6;
  }
</style>
