<template>
  <div class="container" :style="getStyles">
    <span class="title">{{ name }}</span>
    <div class="hr" />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlockTitle',
  props: {
    name: {
      type: String,
      default: '标题'
    },
    marginTop: {
      type: [Number, String],
      default: 30
    }
  },
  computed: {
    getStyles() {
      let style = `margin-top: ${this.marginTop}px`
      return style
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.title {
  padding-left: 10px;
  color: #303030;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  border-left: 5px solid $primary;
}

.hr {
  width: 100px;
  height: 1px;
  margin: 0 20px;
  border-top: 1px dashed #dcdcdc;
  flex: 1 1 auto;
}
</style>
